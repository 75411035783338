<template>
  <div class="WJgroupHonor">
    <headert></headert>
    <el-row class="content ">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="top-banner">
          <img src="../../../assets/image/banner/team.png" alt="">
        </div>
      </el-col>
    </el-row>
    <div>
      <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
      <el-row class=" WJgroupIntroduce-Box " type="flex" justify="center" align='center'>
        <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">

          <div class="padding-tb70">
            <div class="WJgroup-titleBox flex">
              <p class="WJgroup-title">{{$t('team.title')}}</p>
            </div>
          </div>
          <div class="WJgroup_teamTips flex justify_center ">
            <span class="WJgroup_teamTl">“</span>
            <p v-if="lang=='zh'" class="hidden-xs">{{$t('team.desc',{num:``})}}</p>
            <p v-if="lang=='zh'" class="hidden-lg hidden-md hidden-sm">{{$t('team.desc',{num:`\n`})}}</p>
            <p v-else>{{$t('team.desc',{num:``})}}</p> <span class="WJgroup_teamTr">”</span>
          </div>
          <div class="flex WJgroup_teamC align-start">
            <div class="WJgroup_teamCl">
              <ul>
                <P class="activeP hidden-xs" :style="{'top':(teamIndex)*80+'px'}"></P>

                <li v-for="(item,index) in typeList" :key="index"  @click="choseTeam(index)" class="flex " :class="lang=='en'?'teamli_en':''">
                  {{item.typename}}
               <div class="hidden-lg hidden-md hidden-sm activeP2box">
                 <p class="activeP2 " v-if="teamIndex==index"></p>
               </div>

                </li>
              </ul>
            </div>
            <div class="WJgroup_teamR">
              <ul v-if="newsMap[typeList[teamIndex].id]">
                <li v-for="(item,index) in newsMap[typeList[teamIndex].id]" :key="index" class="flex wow animate__animated bounceInUp"  :data-wow-delay="(index/10)+'s'" :class="{'flex-row-reverse':index%2==1}">
                  <div class="WJgroup_teamRImg ">
                    <img :src="item.litpic" alt="">
                  </div>
                  <div style="width: 32px;height: 1px"></div>
                  <div class="flex1 " >
                    <h5>{{item.title}}</h5>
                    <h6>{{item.subtitle}}</h6>
                    <div>
                      <p style="white-space: pre-line;" v-html="item.idea"></p>
                      <p style="white-space: pre-line;" v-html="item.introduce"></p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

        </el-col>

      </el-row>
    </div>
    <el-row>

      <el-col>
        <footerb></footerb>
      </el-col>

    </el-row>
    <!-- 底部 -->

  </div>
</template>

<script>
  export default {
    name: "WJgroupIntroduce",
    components: {},
    data() {
      return {
        menuActiveIndex: '/WJgroupTeam',
        menu: {
          name: '维佳集团',
          name_en:this.$t('menu.talented'),
          path: '/WJgroupIntroduce',
          list: [{
            name: this.$t('menu.Introduction'),
            path: '/WJgroupIntroduce',
          }, {
            name: this.$t('menu.Message'),
            path: '/WJgroupSendWord',
          },{
            name: this.$t('menu.develop'),
            path: '/WJgroupDevelop',
          }, {
            name:this.$t('menu.honour'),
            path: '/WJgroupHonor',
          }, {
            name:this.$t('menu.team'),
            path: '/WJgroupTeam',
          }]
        },
        breadcrumb: [{
          name: this.$t('menu.group'),
          path: '/WJgroupIntroduce',
        }, {
          name: this.$t('menu.team'),
          path: '/WJgroupTeam',
        }],

        teamIndex:0,
        // newsList:[],
        newsMap:{},
        typeid:75,
        typeList:[]
      };
    },
    computed: {
      lang(){
        return window.localStorage.getItem('language')
      },
    },
    created() {
      this.getTypeList();
      this.getData();
    },
    mounted() {
        new this.$wow.WOW().init()
    },
    methods: {
      getTypeList(){
        this.$init.getChannelList(channelList=>{
          let typeList = channelList.filter(item=>{
            return item.parent_id==this.typeid
          });
          this.typeList =this.$utils.translate(typeList);
        })
      },
      getData(){
        let api='?m=api&c=v1.Api&a=index&_ajax=1';
        this.newsList=[];
        this.$request.post(api,{
          apiArclist_1:`ekey=1&typeid=${this.typeid}&limit=100&addfields=idea,introduce,title_en,subtitle_en,idea_en,introduce_en`,//获取栏目文章列表
        }).then(res=>{
          let newsList =this.$utils.translate(res.apiArclist[1].data);
          this.newsMap = this.$utils.arrayChangeKey(newsList ,'typeid','true');
        })
      },
      choseTeam(e){
        this.teamIndex=e
      },
      setActiveItem(index) {
        this.$refs.Carousel.setActiveItem(index)
      },
      carouselChange(e) {
        console.log(e);
        this.yearIndex = e
      },
      choseYears(index) {
        this.yearIndex = index
      },
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style lang="less" scoped>
  .WJgroup_teamTips p {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 46px;
    text-align: center;
    color: #8F6B4B;
    white-space: pre-line;
    // width: 50%;
  }

  .WJgroup_teamTips span {
    font-family: 'PangMenZhengDao';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 73px;
    /* identical to box height */
    color: #A4825F;
  }

  .WJgroup_teamTl {
    margin-bottom: 22px;
    margin-right: 24px;
  }

  .WJgroup_teamTr {
    margin-left: 24px;
    margin-bottom: -70px;
  }

  .WJgroup_teamC {
    margin-top: 118px;
  }

  .WJgroup_teamCl {
    width: 225px;
    min-height: 946px;
    border-right: 1px solid #EBEBEB;
    flex-shrink: 0;

  }

  .WJgroup_teamCl ul {
    width: 100%;
    position: relative;
  }

  .WJgroup_teamCl ul li {
    width: 100%;
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    height: 80px;
    text-align: center;
    letter-spacing: 3.2px;
    color: #7D8083;
    cursor: pointer;
  }
  .WJgroup_teamCl .teamli_en{
    font-size: 20px;
    letter-spacing: 0;
  }

  .WJgroup_teamCl ul .activeP {

    width: 4px;
    height: 80px;
    background: #A4825F;
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.5s;

  }

  .WJgroup_teamR {
    flex: 1;
    margin-left: 66px;
  }

  .WJgroup_teamR li {
    padding-bottom: 72px;
    margin-bottom: 72px;
    border-bottom: 2px dashed #E2E2E2;
  }
    .WJgroup_teamR li:last-child{
      border-bottom: 0!important;
    }
  .WJgroup_teamR li .WJgroup_teamRImg {
    width: 32%;
    padding-top: 38.5%;
    position: relative;
    flex-shrink: 0;
    overflow: hidden;
    //margin-right: 32px;
  }
  .WJgroup_teamR li .WJgroup_teamRImgr{
    margin-left: 32px;
    margin-right: 0;
  }
    .WJgroup_teamR li .WJgroup_teamRImg:hover img{
        transform: scale(1.1);
    }
  .WJgroup_teamR li .WJgroup_teamRImg img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    transition: 1s;
  }

  .WJgroup_teamR li h5 {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;
    letter-spacing: 2.5px;
    color: #1F2D39;
  }

  .WJgroup_teamR li h6 {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #445375;
    margin-top: 14px;
    margin-bottom: 11px;
  }

  .WJgroup_teamR li p {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 1.125px;

    color: #1F2D39;
    margin-bottom: 20px;
  }
  @media screen and (min-width:320px) and (max-width:767px) {
    .WJgroupHonor .WJgroup_teamTips p{
      font-size: 39px;
      line-height: 49px;
    }
   .WJgroupHonor .WJgroup_teamC{
      display:block;
    }
    .WJgroupHonor .WJgroup_teamCl{
      height: auto;
      min-height: 0;
      width:100%;
      border-left: 0;
      border-bottom:1px solid #EBEBEB ;
    }


    .WJgroup_teamCl ul {
      display: flex;
    }
    .WJgroup_teamCl ul li{
      font-size: 34px;
      width: auto;
      padding: 0 20px;
      position: relative;
    }
    .WJgroupHonor .WJgroup_teamCl ul li .activeP2box{
      width: 80px;
      height: 4px;

      position: absolute;
      bottom: 0px;
      left: 50%;
      transition: 0.5s;
      margin-left: -40px;
    }
    .WJgroupHonor .WJgroup_teamCl ul li .activeP2{
      background: #A4825F;
      width: 100%;
      height: 100%;
    }
    .WJgroup_teamR li{
      display:block;
    }
    .WJgroup_teamR{
      margin-top: 80px;
      margin-left: 0;
    }
     .WJgroup_teamR .WJgroup_teamRImg{
      width: 100%!important;
      padding-top: 100%!important;
    }
    .WJgroup_teamR li h5{
      margin-top: 30px;
      font-weight: bold;
    }
    .WJgroup_teamR li p{
      font-size: 34px;
      line-height: 44px;
    }
  }
</style>
